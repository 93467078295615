<template>
  <div class="content">
    <van-nav-bar :title="$t('Cart.text.one')">
      <!-- <van-icon slot="left" name="arrow-left" color="#fff" @click="$router.back()" /> -->
    </van-nav-bar>
    <!-- <div class="title">
      <p>{{ $t("Cart.text.one") }}</p>
    </div> -->
    <div class="money_info_wrap">
      <div class="money_content">
        <div class="left money_item">
          <div class="item_content">
            <div class="title">
              <!-- Balance -->{{ $t("newAdd.blance") }}
            </div>
            <div class="data">
              <!-- {{ `${verConfig.moneyType}${userInfo.money || 0}` }} -->
              {{ $money(userInfo.money || 0) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="hint">{{ $t("newAdd.provided") }}</div> -->
    <Tabs v-model="tabsActive" color="var(--theme)" title-inactive-color="var(--sizeColor)"
      title-active-color="var(--theme)" background="var(--bg)" v-if="ver != 'amigo_vi'">
      <Tab v-for="item, index in tabsData" :key="index" :name="item.value" :title="$t(item.title)"></Tab>
    </Tabs>
    <div class="wrap">
      <NewRecord v-if="tabsActive == 1" :isThird="true" />
      <van-list v-if="tabsActive == 0" v-model="loading" :finished="finished" :finished-text="$t('Hint.notMore')"
        :loading-text="$t('Hint.loading')" @load="getTaskOrder">
        <div class="nomore" v-if="!newList.length && !listLoading">
          <img :src="verConfig.nullPng || require('../../assets/Img/null/null.png')" alt="" />
        </div>
        <div class="goods" v-for="(item, index) in newList" :key="index"
          :style="index == 0 ? 'margin-top: calc(8rem / 16)' : ''" v-show="newList.length && item.status != 5">
          <div class="good_content">
            <div class="top">
              <div class="price">{{ $money(item.total_price, false) }}</div>
              <div class="shop_name" v-if="item.status">
                {{ $t("newAdd.Transaction") }}&nbsp;{{
      item.status == 1
        ? "Frozen"
        : item.status == 2
          ? "Done"
          : "Canceled"
    }}
              </div>
              <div class="tobuy" v-if="!item.status" @click="toPayDialog(item, index)">
                <div v-if="buyLoading == index" class="loading">
                  <van-loading size="24" />
                </div>
                <span v-else>{{ $t("newAdd.ImmediatePayment") }}</span>
              </div>
            </div>
            <div class="bottom">
              <div class="left" @click="toShoppingDetail(item)">
                <img :src="`${domain}${item.picture}`" alt="" />
              </div>
              <div class="right">
                <div class="return">
                  {{ $t("newAdd.ExpectedReturn") }}&nbsp;
                  {{ toFixed((item.total_price * item.rate) / 100, 2) }}
                </div>
                <div class="desc" @click="toShoppingDetail(item)">
                  {{ item.title }}
                </div>
                <div class="time">{{ $format(item.indate) }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div style="height: calc(200rem / 16)"></div> -->
      </van-list>
    </div>
    <!-- 弹窗类 -->
    <div class="buy-box" v-if="isBuyBoxShow">
      <div class="cont">
        <div>
          <div class="goodsInfo">
            <div class="close" @click="closeBuy">
              <img src="../../assets/icon/close.png" alt="" />
            </div>
            <p>{{ $t("Cart.zzInfo.buyIng") }}······</p>
          </div>
          <div>
            <van-progress :percentage="percentage" stroke-width="20" />
          </div>
          <span class="space-text">{{
      $t("Cart.zzInfo.buySucceedFindDT")
    }}</span>
        </div>
      </div>
      <div class="zz" @click="handleColesZZ"></div>
    </div>
    <div class="buy-fail" v-if="isBuyFailShow">
      <div class="cont">
        <div>
          <p>{{ $t("Cart.zzInfo.buyFailed") }}</p>
          <p>{{ $t("Cart.zzInfo.notLevel") }}</p>
          <p v-if="level == 2">{{ $t("Cart.zzInfo.buyJurisdiction1") }}</p>
          <p v-if="level == 3">{{ $t("Cart.zzInfo.buyJurisdiction2") }}</p>
          <p v-if="level == 4">{{ $t("Cart.zzInfo.buyJurisdiction3") }}</p>
          <p></p>
          <p v-if="level == 2">{{ $t("Cart.zzInfo.invite1") }}</p>
          <p v-if="level == 3">{{ $t("Cart.zzInfo.invite4") }}</p>
          <p v-if="level == 4">{{ $t("Cart.zzInfo.invite3") }}</p>
          <p></p>
          <div class="btn-box">
            <button @click="handleInviteFriends">
              {{ $t("Cart.zzInfo.invite2") }}
            </button>
          </div>
        </div>
      </div>
      <div class="zz" @click="handleColesZZ"></div>
    </div>
    <div class="buy-fail-not-money" v-if="isBuyFailNotMoneyShow">
      <div class="cont">
        <div>
          <p>{{ $t("Cart.zzInfo.myMoney") }}</p>
          <p>{{ $t("Cart.zzInfo.under") }}</p>
          <p>{{ $t("Cart.zzInfo.notSendMoney") }}</p>
          <p>{{ $t("Cart.zzInfo.goTopUp") }}</p>
          <p>{{ $t("Cart.zzInfo.know") }}</p>
          <button @click="handlecolsePopBox">
            {{ $t("Cart.zzInfo.account") }}
          </button>
        </div>
      </div>
      <div class="zz" @click="handleColesZZ"></div>
    </div>
    <van-popup v-model="popupShow">
      <div class="popup">
        <p>{{ $t("Cart.hint.p1") }}</p>
        <p>{{ $t("Cart.hint.p2") }}</p>
        <p>{{ $t("Cart.hint.p3") }}</p>
      </div>
    </van-popup>
    <van-dialog v-model="inputPayPassShow" :title="$t('dialog.title.inputPaypass')" show-cancel-button
      :confirm-button-text="$t('Button.confirm')" show-confirm-button :cancel-button-text="$t('Button.cancle')"
      @confirm="toPay(...temp)" @cancel="dialogDone">
      <div class="password_input">
        <input :type="isShowPass ? 'text' : 'password'" :placeholder="$t('Login.pwdInput')" v-model="paypass" />
        <img v-if="isShowPass === true" src="@/assets/icon/Lark20220424-175950.png" @click="isShowPass = !isShowPass" />
        <img v-if="isShowPass === false" src="@/assets/icon/Lark20220424-180000.png"
          @click="isShowPass = !isShowPass" />
      </div>
    </van-dialog>
    <!-- <Tabbar active="cart" /> -->
    <van-overlay :show="overlayShow" @click="overlayShow = false" z-index="9999">
      <div class="wrapper" @click.stop>
        <div class="wrapper_title">{{ $t('gift.away') }}</div>
        <div class="blocks">
          <div class="blocks_img">
            <div class="imgs">
              <img :src="sendItem && $img(sendItem.product_picture)" alt="">
              <div class="title">{{ sendItem && sendItem.product_name }}</div>
              <div class="amount">x{{ sendItem.amount }}</div>
            </div>
          </div>
          <div class="blocks_cont">
            <div class="cont_left">{{ $t('gift.overlay.room') }}:</div>
            <div class="cont_value">
              <input v-model="remark" class="input" type="text" :placeholder="$t('gift.overlay.inputPlaceholder')">
            </div>
          </div>
          <div class="btn" :style="theme == 'light' ? { color: 'var(--btnsizeColor)' } : { color: '#000' }"
            @click="handleSend">
            {{ $t('gift.away') }}
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  Checkbox,
  CheckboxGroup,
  Card,
  SubmitBar,
  Toast,
  Icon,
  List,
  Cell,
  progress,
  Popup,
  NoticeBar,
  CountDown,
  Dialog,
  Tabs,
  Tab,
  NavBar,
  Overlay,
  Stepper
} from "vant";
import Tabbar from "@/components/Tabbar";
import { domain } from "@/utils/request";
import { REQUEST_API } from "@/http/api";
import { countTime, getMyTime, deciTurnMoney } from "@/utils/tools";
import { formatDate } from "@/utils/tools";
import verConfig, { ver } from "@/utils/verConfig";
import NewRecord from "../bank/thirdRechargeList.vue"
import { PAY_TASKORDER } from '@/api'

import { mapGetters } from "vuex"
export default {
  components: {
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [CheckboxGroup.name]: CheckboxGroup,
    [Icon.name]: Icon,
    [List.name]: List,
    [Cell.name]: Cell,
    [progress.name]: progress,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [CountDown.name]: CountDown,
    [Dialog.Component.name]: Dialog.Component,
    Tabbar,
    Toast,
    Tabs,
    Tab,
    NewRecord,
    [NavBar.name]: NavBar,
    [Overlay.name]: Overlay,
    [Stepper.name]: Stepper
  },

  data() {
    this.tabsData = [
      { title: "order.new", value: 1 },
      { title: "order.old", value: 0 }
    ]
    return {
      // tabsActive: ver == "amigo_vi" ? 1 : 0,
      sendItem: {},
      remark: '',
      overlayShow: false,
      tabsActive: 0,
      verConfig,
      domain: domain,
      buyLoading: -1,
      listLoading: false,
      tableList: [
        {
          title: "V1",
        },
        {
          title: `2`,
        },
        {
          title: `3`,
        },
        {
          title: `4`,
        },
      ],
      statusBarList: [
        {
          label: `${this.$t("Cart.state.all")}`,
          key: null,
        },
        {
          label: `${this.$t("Cart.state.going")}`,
          key: "ing",
        },
        // {
        //   label: `${this.$t('Cart.state.paid')}`,
        //   key: 1,
        // },
        // {
        //   label: `${this.$t('Cart.state.frozen')}`,
        //   key: 5,
        // },
        {
          label: `${this.$t("Cart.state.over")}`,
          key: 2,
        },
      ],
      tableKey: 0,
      statusKey: null,
      moneyInfoList: [
        {
          label: `${this.$t("Cart.info.allMoney")}`,
          key: "allMoney",
          value: 0,
        },
        {
          label: `${this.$t("Cart.info.dayAllOrder")}`,
          key: "dayAllOrder",
          value: 0,
        },
        {
          label: `${this.$t("Cart.info.dayTrustMoney")}`,
          key: "dayTrustMoney",
          value: 0,
        },
        {
          label: `${this.$t("Cart.info.yesterdayMoney")}`,
          key: "yesterdayMoney",
          value: 0,
        },
      ],
      list: [],
      loading: false,
      finished: false,
      isBuyBoxShow: false,
      isBuyFailShow: false,
      isBuyFailNotMoneyShow: false,
      percentage: 0,
      level: 1,
      pageNo: 1,
      buyGoodsForm: {}, // 购买当前商品信息，可用可不用
      popupShow: false,
      interval: "",
      pageNow: 1,
      newList: [],
      userInfo: {},
      inputPayPassShow: false,
      paypass: "",
      isShowPass: false,
      temp: {},
      isFirstGet: true,
    };
  },

  computed: {
    ...mapGetters(["theme"]),
    totalPrice() {
      return this.goods.reduce(
        (total, item) =>
          total + (this.checkedGoods.indexOf(item.id) !== -1 ? item.price : 0),
        0
      );
    },
  },
  created() {
    this.getTaskOrder();
    this.getUserInfo();
  },
  mounted() {
    this.initTitlePosition();
  },
  watch: {
    finished(val) {
    },
  },
  methods: {
    async handleSend() {
      try {
        const res = await PAY_TASKORDER({ id: this.sendItem.id, remark: this.remark });
        Toast(res.msg);
        this.getTaskOrder({},{},true);
        this.getUserInfo();
        this.overlayShow = false;
      } catch (error) {
        Toast(error.msg)
      }
    },

    toShoppingDetail(item) {
      if (item.url && item.url !== "") {
        window.open(item.url);
      }
    },
    dateEvent(indate) {
      return formatDate("", indate);
    },
    getUserInfo() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      this.$api.getUserInfo(
        (res) => {
          this.userInfo = res;
          Toast.clear();
        },
        (err) => {
          Toast.clear();
        }
      );
    },
    /** handle **/
    handleFindExplain() {
      this.popupShow = true;
    },
    handleTable(index) {
      this.listLoading = true;
      if (index === this.tabelKey) {
        return false;
      }
      this.tableKey = index;
      this.getTaskOrder();
    },
    statusSearch(item) {
      this.newList = [];
      this.listLoading = true;
      if (item.key === this.statusKey) {
        return false;
      }
      this.statusKey = item.key;
      this.getTaskOrder();
    },
    handleBuy(item) {
      if (this.userInfo.level) {
        if (this.userInfo.level >= this.tableKey) {
          this.isBuyBoxShow = true;
          this.percentage = 0;

          let auth_timetimer = setInterval(() => {
            this.percentage++;
            if (this.percentage > 30) {
              clearInterval(auth_timetimer);
            }
          }, 20);
          let auth_timetimer2 = setInterval(() => {
            if (this.percentage >= 31 && this.percentage <= 70) {
              this.percentage++;
            }
            if (this.percentage > 60) {
              clearInterval(auth_timetimer2);
            }
          }, 80);

          this.$api.getTask(
            { level: this.tableKey },
            (res) => {
              let auth_timetimer3 = setInterval(() => {
                if (this.percentage >= 61 && this.percentage < 100) {
                  this.percentage++;
                }
                if (this.percentage === 100) {
                  clearInterval(auth_timetimer3);
                  this.interval = setInterval(() => {
                    this.$api.getTask({ level: this.tableKey }, (result) => {
                      if (result.status == 1) {
                        this.getTaskOrder();
                        clearInterval(this.interval);
                      }
                    });
                  }, 5000);
                  setTimeout(() => {
                    this.isBuyBoxShow = false;
                    this.$toast(
                      "Order has been obtained, please wait for the order!"
                    );
                  }, 300);
                }
              }, 30);
            },
            (err) => {
              let auth_timetimer3 = setInterval(() => {
                if (this.percentage >= 61 && this.percentage < 99) {
                  this.percentage++;
                }
                if (this.percentage === 99) {
                  clearInterval(auth_timetimer3);
                  setTimeout(() => {
                    this.isBuyBoxShow = false;
                    if (typeof err == "string") {
                      this.$toast(err + "!");
                    } else {
                      this.$toast("Network timeout, please try again later!");
                    }
                  }, 20000);
                }
              }, 100);
            }
          );
        } else {
          this.$toast("Grade mismatch!");
        }
      } else {
        this.$api.getUserInfo();
        this.handleBuy();
      }
    },
    handleBuyPercentage(item) {
      if (this.percentage < 100) {
        return false;
      }
      this.sendBuy(this.buyGoodsForm.id);
    },
    handleInviteFriends() {
      this.$router.push("InviteFriends"); // 邀请好友
    },
    handleBuyVip() {
      this.isBuyFailShow = false;
      this.$router.push("Recharge");
    },
    handlecolsePopBox() {
      this.isBuyBoxShow = false;
      this.isBuyFailShow = false;
      this.isBuyFailNotMoneyShow = false;
      this.buyGoodsForm = {};
    },
    handleColesZZ() {
      this.isBuyBoxShow = false;
      this.isBuyFailShow = false;
      this.isBuyFailNotMoneyShow = false;
      this.buyGoodsForm = {};
    },
    /** api **/
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        // 加载状态结束
        this.loading = false;
        this.finished = true;
        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    getGoodsList() {
      if (this.finished) {
        return false;
      }
      let form = {
        uid: this.userInfo[1].data.player.uid,
        level: this.level,
        cmd: "201002",
      };
      REQUEST_API(form)
        .then((r) => {
          if (!r.data.sc) {
            if (r.data.notifyList[0].data.length) {
              this.list.push(...r.data.notifyList[0].data);
              this.finished = true;
              this.pageNo++;
              if (this.finished) {
                this.loading = false;
              }
            } else {
              this.list = [];
              this.loading = false;
              this.finished = true;
            }
          } else {
            Toast(this.$t("Hint.failed"));
            this.list = [];
            this.loading = false;
            this.finished = true;
          }
        })
        .catch((e) => {
          console.info(e);
        });
    },
    getMoneyInfo() {
      let form = {
        uid: this.userInfo[1].data.player.uid,
        cmd: "201004",
      };
      REQUEST_API(form)
        .then((r) => {
          if (!r.data.sc) {
            let moneyForm = r.data.notifyList[0].data;
            this.moneyInfoList[0].value = deciTurnMoney(moneyForm.diamond) || 0;
            this.moneyInfoList[1].value = moneyForm.size || 0;
            this.moneyInfoList[2].value = deciTurnMoney(moneyForm.price) || 0;
            this.moneyInfoList[3].value =
              deciTurnMoney(moneyForm.last_reward) || 0;
          } else {
            Toast(this.$t("Hint.failed"));
          }
        })
        .catch((e) => {
          console.info(e);
        });
    },
    sendBuy(id) {
      let form = {
        id,
        cmd: "201003",
        uid: this.userInfo[1].data.player.uid,
      };
      REQUEST_API(form)
        .then((r) => {
          if (!r.data.sc) {
            Toast(this.$t("Hint.success"));
            this.handleColesZZ();
            this.list = [];
            this.finished = false;
            this.pageNo = 1;
            this.getMoneyInfo();
          } else if (r.data.sc === 1002) {
            this.isBuyBoxShow = false;
            // this.isBuyFailShow = true
            this.isBuyFailNotMoneyShow = true;
            Toast(this.$t("Hint.failed"));
          } else if (r.data.sc === 1211) {
            Toast(this.$t("Hint.goodsNumNo"));
          } else {
            Toast(this.$t("Hint.failed"));
          }
        })
        .catch((e) => {
          console.info(e);
        });
    },
    /** 其他 **/
    init() {
      this.userInfo = JSON.parse(localStorage.getItem("user-info"));
      this.level = this.$route.query.id ? Number(this.$route.query.id) + 1 : 1;
      this.tableList.forEach((_, i) => {
        if (i + 1 === this.level) {
          this.tabelKey = _.key;
        }
      });
    },
    initTitlePosition() {
      if (this.tableKey > 2) {
        let title_wrap = document.querySelector(".classify");
        title_wrap.scrollLeft = document.documentElement.clientWidth;
      }
    },
    getLevels() {
      this.$api.getLevel((res) => {
        this.tableList = res;
      });
    },
    closeBuy() {
      this.isBuyBoxShow = false;
    },
    getTaskOrder(success, fail, reload = false) {
      this.listLoading = true;
      if (reload) {
        this.newList = [];
        this.isFirstGet = true;
        this.pageNow = 1;
        this.finished = false;
      }
      if (this.finished) {
        return false;
      }
      this.finished = false;
      if (!this.isFirstGet && !this.reload) {
        this.pageNow++;
      }
      this.isFirstGet = false;
      let data = {
        // level: this.tableKey,
        page: this.pageNow,
        // status: this.statusKey
      };
      this.$api.getTaskOrder(
        data,
        (res) => {
          // if (res.currentPage >= this.pageNow) {
          if (res.currentPage == 1) {
            this.newList = [...res.lists, ...this.newList];
          } else {
            this.newList = [...this.newList, ...res.lists];
          }
          // }
          // if (this.pageNow == 1) {
          //   this.newList = res.lists;
          // } else {
          //   for (let i = 0; i < res.lists.length; i++) {
          //     const item = res.lists[i];
          //     this.newList.push(item);
          //   }
          //   this.newList.concat(res.lists);
          // }
          this.listLoading = false;
          this.loading = false;
          // !res.hasMorePages && (this.finished = true);
          if (res.currentPage == res.lastPage) {
            this.finished = true;
          }
          // this.finished = true;
          if (success) {
            success(res);
          }
        },
        (err) => {
          // this.newList = [];
          this.listLoading = false;
          this.loading = false;
          this.finished = true;
          if (fail) {
            fail(err);
          }
        }
      );
    },
    dialogDone() {
      this.temp = [];
      this.paypass = "";
      this.inputPayPassShow = false;
      this.isShowPass = false;
    },
    async toPayDialog(row, index) {
      // if (this.buyLoading !== index) {
      //   this.buyLoading = index;
      //   try {
      //     await this.hasPaypass();
      //     this.temp = [row, index];
      //     this.inputPayPassShow = true;
      //   } catch (error) {
      //     this.buyLoading = -1;
      //   }
      // }
      this.toPay(row, index)
    },
    async toPay(row, index) {
      this.sendItem = row
      // if (this.buyLoading !== index) {
      // this.buyLoading = index;
      const data = {
        goods_image: this.verConfig.url + row.product_picture,
        goods_name: row.product_name,
        goods_price: row.price,
        id: row.id,
        indate: row.indate
      }
      let pathUrl = '/ThirdRecharge'
      let type = 'third'
      if (this.userInfo.money > row.total_price || this.userInfo.money == row.total_price) {
        return this.overlayShow = true
      }
      if (this.verConfig.pathNewRecharge) {
        pathUrl = '/third-recharge-n'
      }
      this.$router.push(`${pathUrl}?type=${type}&data=${JSON.stringify(data)}`);
      localStorage.setItem("rechargeGoodDataInfo", JSON.stringify({ data: data, type: "own" }));
      return;
      this.$api.payTaskOrder(
        { id: row.id, paypass: this.paypass },
        (res) => {
          if (res.ret == 1) {
            this.newList[index].status = res.data.status;
            if (!this.newList.filter((item) => item.status == 0).length) {
              this.getTaskOrder(
                () => { },
                () => { },
                true
              );
            }
            this.$api.getUserInfo(
              () => { },
              () => { },
              () => {
                console.log(JSON.parse(localStorage.getItem("userInfo")));
                this.userInfo.money = JSON.parse(
                  localStorage.getItem("userInfo")
                ).money;
              }
            );
          } else {
            Toast(res.msg);
          }

          this.getTaskOrder(
            (res) => {
              this.buyLoading = -1;
              console.log("success--------------------");
            },
            (err) => {
              console.log("fasil--------------------");
              this.$toast(err);
            }
          );
        },
        (err) => {
          this.buyLoading = -1;
          if (err.ret == 0) {
            this.$toast(err.msg);
            setTimeout(() => {
              if (this.verConfig.pathNewRecharge) this.$router.push({ name: "thirdRechargeNew" });
              else this.$router.push({ name: "NewRecharge" });
            }, 1000);
          } else {
            if (err.msg) {
              this.$toast(err.msg);
            }
          }
        }
      );
    },
  },
  // },
};
</script>

<style lang="less" scoped>
@Color: var(--theme);
@background: var(--nav-background);
@bg: var(--bg);
@sizeColor: var(--sizeColor);

// 弹性布局
.flex(@JC: center; @FW: nowrap; @AI: center; @FD: row) {
  display: flex;
  flex-wrap: @FW;
  flex-direction: @FD;
  justify-content: @JC;
  align-items: @AI;
}

// 文字超出省略号
.text-overflow(@num: 1) {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: @num;
}

.van-hairline--bottom::after {
  border: none;
}

.content {
  // height: calc(100vh - (50rem / 16));
  height: 100vh;
  // background: rgb(248, 248, 248);
  background: @background;
  overflow: hidden;

  >.title {
    color: #fff;
    height: calc(49rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--nav-background);
    font-weight: 400;
    font-size: calc(18rem / 16);
    position: relative;
    width: 100%;
    margin-bottom: calc(12rem / 16);
  }

  >.money_info_wrap {
    width: 100%;
    // background: #fff;
    background: @bg;
    position: relative;
    padding: calc(14rem / 16) 0;
    min-height: calc(26rem / 16);
    margin-bottom: calc(12rem / 16);

    >.line_center_wrap {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      >.line_center {
        width: 1px;
        height: 80%;
        background-color: rgb(226, 226, 226);
      }
    }

    >.money_content {
      width: 100%;
      height: 100%;
      display: flex;
      // justify-content: space-between;
      justify-content: center;
      align-items: center;

      >.money_item {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        >.item_content {
          padding: 0 calc(6rem / 16);
          text-align: center;
          line-height: 1.5;
          font-weight: bold;

          >.title {
            font-size: calc(12rem / 16);
            color: @sizeColor;
          }

          >.data {
            font-size: calc(16rem / 16);
            color: @Color;
          }
        }
      }
    }
  }

  >.hint {
    width: 100%;
    padding: calc(4rem / 16) 0;
    background-color: @Color;
    color: #fff;
    text-align: center;
    font-size: calc(12rem / 16);
  }

  .placeholder {
    width: 100%;
    height: calc(12rem / 16);
    background-color: rgb(248, 248, 248);
  }

  /deep/ .wrap {
    height: calc(100vh - (50rem / 16) - (49rem / 16) - (38rem / 16) - (90rem / 16));
    // background-color: rgb(248, 248, 248);
    background-color: @bg !important;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 1000px;

    .nomore {
      // width: 100%;
      padding: calc(40rem / 16);

      >img {
        width: 100%;
      }
    }

    .goods {
      width: 100%;
      // height: calc(145rem / 16);
      // background: #fff;
      background-color: @bg;
      margin-bottom: calc(8rem / 16);

      >.good_content {
        height: 100%;
        padding: 0 calc(16rem / 16);

        >.top {
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-size: calc(14rem / 16);
          font-weight: bold;
          padding: calc(8rem / 16) 0;

          >.price {
            color: @Color;
          }

          >.shop_name {
            color: #eaad5f;
          }

          >.tobuy {
            // color: #fff;
            // color: @Color;
            color: var(--btnsizeColor);
            padding: calc(6rem / 16) calc(6rem / 16);
            // background-color: @background;
            background-color: var(--newBtn);
            border-radius: 4px;
          }
        }

        >.bottom {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: calc(8rem / 16) 0;
          line-height: 1;

          >.left {
            max-width: 40%;

            >img {
              width: 22vw;
              height: 24vw;
              border-radius: 2px;
            }
          }

          >.right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            max-width: 60%;

            >.return {
              color: @Color;
              font-size: calc(14rem / 16);
              margin-bottom: calc(12rem / 16);
            }

            >.desc {
              // color: #666;
              color: @sizeColor;
              font-size: calc(12rem / 16);
              margin-bottom: calc(38rem / 16);
              max-width: 100%;
              word-break: break-all;
            }

            >.time {
              // color: #999;
              color: @sizeColor;
              font-size: calc(12rem / 16);
            }
          }
        }
      }

      >.vantActive {
        width: 100%;
      }
    }
  }

  >.statusBar {
    // width: 98%;
    width: 100%;
    display: flex;
    margin: 0 auto;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;

    >div {
      width: 33.33%;
      height: calc(38rem / 16);
    }
  }

  >.buy-box {
    width: 100%;
    height: 100%;
    // position: relative;
    position: fixed;
    // top: -100%;
    top: 0;

    >.cont {
      width: calc(350rem / 16);
      height: calc(460rem / 16);
      // background: url('../../assets/Img/goods/img/DDSC_0001_xdz.png') no-repeat;
      position: absolute;
      z-index: 44;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      >div {
        width: calc(350rem / 16);
        height: calc(460rem / 16);
        background: url("../../assets/Img/goods/img/DDSC_0001_xdz.png") no-repeat;
        background-size: 100% 100%;

        >.goodsInfo {
          padding-top: calc(250rem / 16);
          display: flex;
          align-items: center;
          // justify-content: space-between;
          margin-bottom: calc(5rem / 16);
          position: relative;

          >.close {
            position: absolute;
            right: calc(10rem / 16);
            top: calc(120rem / 16);
          }

          >div {
            display: flex;
            align-items: center;
            font-size: calc(13rem / 16);
            color: #9b8383;

            >img {
              width: calc(30rem / 16);
              height: calc(30rem / 16);
              border-radius: 6px;
            }

            >div {
              display: flex;
              flex-direction: column;
              margin-left: calc(15rem / 16);
              width: calc(70rem / 16);

              >span {}
            }
          }

          >p {
            font-size: calc(18rem / 16);
            text-align: center;
            color: #9b8383;
            // margin-bottom: calc(2rem / 16);
            margin: 0 0 calc(2rem / 16) calc(15rem / 16);
          }
        }

        >div {
          width: 98%;
          margin-left: calc(6rem / 16);
          // margin: auto;
        }

        >span {
          font-size: calc(18rem / 16);
          display: block;
          text-align: center;
          color: #9b8383;
          // margin: calc(15rem / 16) calc(22rem / 16) calc(2rem / 16) 0;
          margin: calc(15rem / 16) 0 calc(2rem / 16) 0;
        }

        >button {
          width: calc(100% - (60rem / 16));
          height: calc(60rem / 16);
          margin: auto;
          display: block;
          // margin-right: calc(40rem / 16);
          border-radius: 30px;
          border: 0;
          color: #fff;
          background: #c4cec9;
          font-size: calc(22rem / 16);
          font-weight: 200;

          &.on {
            background: #48db8d;
          }
        }
      }
    }
  }

  >.buy-fail {
    width: 100%;
    height: 100%;
    position: relative;
    top: -100%;

    >.cont {
      width: calc(350rem / 16);
      height: calc(350rem / 16);
      position: absolute;
      z-index: 44;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      >div {
        width: calc(350rem / 16);
        height: calc(350rem / 16);
        background: url("../../assets/Img/goods/img/DDSC_0000_sbdb.png") no-repeat;
        background-size: 100% 100%;
        color: #9b8383;

        >p {
          text-align: center;
          font-size: calc(17rem / 16);
        }

        >p:nth-child(1) {
          font-size: calc(30rem / 16);
          line-height: calc(75rem / 16);
          margin-bottom: calc(2rem / 16);
        }

        >p:nth-child(2) {}

        >p:nth-child(3) {
          font-size: calc(14rem / 16);
          margin: calc(20rem / 16) 0 calc(4rem / 16);
        }

        >p:nth-child(4) {
          color: #e6a30c;
          margin-bottom: calc(15rem / 16);
          font-size: calc(14rem / 16);
        }

        >.btn-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          justify-content: center;
          width: 90%;
          margin: calc(28rem / 16) auto 0;

          >button {
            width: calc(130rem / 16);
            width: calc(260rem / 16);
            height: calc(55rem / 16);
            border-radius: 35px;
            border: 0;
            background: #48db8d;
            color: #fff;
            font-size: calc(20rem / 16);
            font-weight: 200;
          }
        }
      }
    }
  }

  >.buy-fail-not-money {
    width: 100%;
    height: 100%;
    position: relative;
    top: -100%;

    >.cont {
      width: calc(350rem / 16);
      height: calc(350rem / 16);
      position: absolute;
      z-index: 44;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      >div {
        width: calc(350rem / 16);
        height: calc(350rem / 16);
        background: url("../../assets/Img/goods/img/DDSC_0000_sbdb.png") no-repeat;
        background-size: 100% 100%;
        color: #9b8383;

        >p {
          text-align: center;
          font-size: calc(20rem / 16);
        }

        >p:nth-child(1) {
          font-size: calc(30rem / 16);
          line-height: calc(80rem / 16);
          margin-bottom: calc(2rem / 16);
        }

        >p:nth-child(2) {
          font-size: calc(30rem / 16);
          font-size: 500;
          margin-bottom: calc(2rem / 16);
        }

        >p:nth-child(3) {
          font-size: calc(30rem / 16);
          font-size: 500;
          margin-bottom: calc(2rem / 16);
          color: #ff0000;
        }

        >p:nth-child(4) {
          font-size: calc(30rem / 16);
          font-size: 500;
          margin-bottom: calc(2rem / 16);
        }

        >p:nth-child(5) {
          font-size: calc(30rem / 16);
          font-size: 500;
          margin-bottom: calc(2rem / 16);
          color: #ff0000;
        }

        >button {
          width: calc(100% - (60rem / 16));
          height: calc(60rem / 16);
          margin: calc(30rem / 16) auto 0;
          display: block;
          border-radius: 30px;
          border: 0;
          color: #fff;
          background: #48db8d;
          font-size: calc(22rem / 16);
          font-weight: 200;
        }
      }
    }
  }

  .popup {
    width: 80vw;
    padding: calc(15rem / 16) calc(20rem / 16);
    font-size: calc(15rem / 16);

    >p {
      margin-bottom: calc(8rem / 16);
    }
  }

  .colon {
    display: inline-block;
    margin: 0 4px;
    color: #b36900;
  }

  .block {
    display: inline-block;
    width: 22px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background-color: #b36900;
  }

  .password_input {
    display: flex;
    justify-content: space-between;
    margin: calc(6rem / 16) calc(12rem / 16);
    align-items: center;
    border: 1px solid #eee;
    border-radius: 4px;

    >input {
      border: none;
    }

    >img {
      width: calc(30rem / 16);
    }
  }
}

/deep/ .van-overlay {
  right: 0;
  display: flex;
  align-items: flex-end;
}

.wrapper {
  width: 100%;
  padding: calc(16rem / 16);

  &_title {
    background-color: var(--bg);
    color: var(--theme);
    text-align: center;
    padding: calc(6rem / 16) 0;
    border-radius: calc(10rem / 16);
  }

  .blocks {
    background-color: var(--bg);
    margin: calc(16rem / 16) 0;
    margin-bottom: 0;
    border-radius: calc(10rem / 16);
    padding: calc(16rem / 16);

    &_img {
      display: flex;
      justify-content: center;

      .imgs {
        display: inline-block;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: calc(16rem / 16) calc(34rem / 16);
        border: 1px solid #363637;
        border-radius: calc(10rem / 16);

        img {
          width: calc(60rem / 16);
          height: calc(60rem / 16);
          display: block;
        }

        .title {
          color: var(--sizeColor);
          font-size: calc(16rem / 16);
          text-align: center;
          margin-top: calc(12rem / 16);
          margin-bottom: calc(8rem / 16);
        }

        .amount {
          text-align: center;
          color: #BBBBBB;
          font-size: calc(12rem / 16);
        }
      }
    }

    &_cont {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: calc(30rem / 16);

      .cont_left {
        color: var(--theme);
        font-size: calc(12rem / 16);
      }

      .cont_value {
        /deep/ .van-stepper__input {
          color: var(--theme);
        }

        .input {
          width: calc(70rem / 16);
          color: var(--theme);
          font-size: calc(12rem / 16);
          background-color: transparent;
          border-color: transparent;
          border-bottom: 1px solid rgb(118, 118, 118), ;
        }
      }
    }

    .btn {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--moduleColor);
      color: var(--btnsizeColor);
      padding: calc(8rem / 16) 0;
      border-radius: calc(10rem / 16);
      margin-top: calc(16rem / 16);
    }
  }
}
</style>
